import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import heroVideo from '../assets/hero.mp4'; 
import aboutVideo from '../assets/about.mp4'; 
import icon1 from '../assets/video_production.png'; 
import icon2 from '../assets/equipments rental.png'; 
import icon3 from '../assets/travel.png';
import icon4 from '../assets/more.png';

import clientIcon1 from '../assets/clients/client1.png';
import clientIcon2 from '../assets/clients/client2.png';
import clientIcon3 from '../assets/clients/client3.png';
import clientIcon4 from '../assets/clients/client4.png';
import clientIcon5 from '../assets/clients/client5.png';
import clientIcon6 from '../assets/clients/client6.png';
import clientIcon7 from '../assets/clients/client7.jpg';
import clientIcon8 from '../assets/clients/client8.png';
import clientIcon9 from '../assets/clients/client9.png';
import clientIcon10 from '../assets/clients/client10.jpg';
import clientIcon11 from '../assets/clients/client11.png';
import clientIcon12 from '../assets/clients/client12.png';
import clientIcon13 from '../assets/clients/client13.jpg';
import clientIcon14 from '../assets/clients/client14.jpg';
import clientIcon16 from '../assets/clients/client16.png';
import m1 from '../assets/clients/m1.png';
import m from '../assets/clients/m.png';


import hireUsBackground from '../assets/bg.jpg'; 
import logo from '../assets/logo.png'; 


import { FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';
import '../styles/Home.css'; 

const Home = () => {
    // Initialize AOS
    React.useEffect(() => {
        AOS.init({
            duration: 1000, // Animation duration
        });
    }, []);

    // Function to handle video popup
    const openPopup = (videoId, title) => {
        const videoPopup = document.getElementById('video-popup');
        const popupVideo = document.getElementById('popup-video');
        const videoTitle = document.getElementById('video-title');
        videoPopup.style.display = 'block';
        popupVideo.src = `https://www.youtube.com/embed/${videoId}?autoplay=1`; // Autoplay the video
        videoTitle.innerText = title; // Set video title
    };

    // Function to close video popup
    const closePopup = () => {
        const videoPopup = document.getElementById('video-popup');
        const popupVideo = document.getElementById('popup-video');
        videoPopup.style.display = 'none';
        popupVideo.src = ''; // Stop the video
    };

    // Add event listeners for closing the popup
    React.useEffect(() => {
        const closePopupButton = document.querySelector('.close-popup');
        const videoPopup = document.getElementById('video-popup');

        closePopupButton.addEventListener('click', closePopup);
        videoPopup.addEventListener('click', (event) => {
            if (event.target === videoPopup) {
                closePopup();
            }
        });

        return () => {
            closePopupButton.removeEventListener('click', closePopup);
            videoPopup.removeEventListener('click', closePopup);
        };
    }, []);

    return (
        <div className="home-page">
            {/* Hero Section */}
<div className="hero" data-aos="fade-in">
    <video autoPlay loop muted className="background-video">
        <source src={heroVideo} type="video/mp4" />
        Your browser does not support the video tag.
    </video>
    <div className="hero-content">
    <img src={logo} alt="Logo" className="mark" />
        <h1>Your Creative Journey Starts Here</h1>
        <p>Bringing Your Vision to Life with Expert Production</p>
        <a href="/about" className="learn-more-btn">Learn More</a>
    </div>
</div>


            {/* What We Do Section */}
            <div className="what-we-do" data-aos="fade-up">
                <h2>What We Do</h2>
                <div className="icons-section">
                    <div>
                        <img src={icon1} alt="Video Production" className="icon" />
                        <p className="icon-text">Video Production</p>
                    </div>
                    <div>
                        <img src={icon2} alt="Gear Rental" className="icon" />
                        <p className="icon-text">Gear Rental</p>
                    </div>
                    <div>
                        <img src={icon3} alt="Travel Coordination" className="icon" />
                        <p className="icon-text">Travel Coordination</p>
                    </div>
                    <div>
                        <img src={icon4} alt="More" className="icon" />
                        <p className="icon-text">More</p>
                    </div>
                </div>
            </div>

            {/* About Us Section */}
            <div className="about-us" data-aos="fade-right">
                <video autoPlay loop muted className="about-video">
                    <source src={aboutVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="about-us-content">
                    <h2>About Us</h2>
                    <p>
                        We are a video production company passionate about turning ideas into visually stunning projects. Our services include video production, gear rental, and full travel coordination for our clients. From concept to completion, we make it easy to bring your vision to life.
                    </p>
                    <a href="/contact" className="contact-us-btn">Contact Us</a>
                </div>
            </div>

            {/* Our Portfolio Section */}
            <div className="our-portfolio" data-aos="fade-left">
                <h2>Our Portfolio</h2>
                <h3>Latest Work</h3>
                <div className="portfolio-videos">
                    <div onClick={() => openPopup('yADhUV2GR-0', '')} className="portfolio-video">
                        <img src={`https://img.youtube.com/vi/yADhUV2GR-0/0.jpg`} alt="Latest Video" />
                    </div>
                    <div onClick={() => openPopup('xcj_8Y4Uh1k', '')} className="portfolio-video">
                        <img src={`https://img.youtube.com/vi/xcj_8Y4Uh1k/0.jpg`} alt="Latest Video" />
                    </div>
                    {/* <div onClick={() => openPopup('YdKguFEfuTE', '')} className="portfolio-video">
                        <img src={`https://img.youtube.com/vi/YdKguFEfuTE/0.jpg`} alt="Latest Video" />
                    </div> */}
                    <div onClick={() => openPopup('tZJTlEJXlYo', '')} className="portfolio-video">
                        <img src={`https://img.youtube.com/vi/tZJTlEJXlYo/0.jpg`} alt="Latest Video" />
                    </div>
                    
                </div>
            </div>

            {/* Video Popup */}
            <div id="video-popup" className="video-popup">
                <span className="close-popup">&times;</span>
                <h4 id="video-title" className="video-title"></h4>
                <iframe id="popup-video" width="560" height="315" allowFullScreen></iframe>
            </div>

            
     

{/* Our Clients Section */}
<div className="our-clients" data-aos="fade-up">
    <h2>Our Clients</h2>
    <div className="client-container">
        <div className="client-card">
            <img src={clientIcon1} alt="Client 1" className="client-icon" />
        </div>
        <div className="client-card">
            <img src={clientIcon2} alt="Client 2" className="client-icon" />
        </div>
        <div className="client-card">
            <img src={clientIcon3} alt="Client 3" className="client-icon" />
        </div>
        <div className="client-card">
            <img src={clientIcon4} alt="Client 4" className="client-icon" />
        </div>
        <div className="client-card">
            <img src={clientIcon5} alt="Client 5" className="client-icon" />
        </div>
        <div className="client-card">
            <img src={clientIcon6} alt="Client 6" className="client-icon" />
        </div>
        <div className="client-card">
            <img src={clientIcon7} alt="Client 7" className="client-icon" />
        </div>
        <div className="client-card">
            <img src={clientIcon8} alt="Client 8" className="client-icon" />
        </div>
        <div className="client-card">
            <img src={clientIcon9} alt="Client 9" className="client-icon" />
        </div>
        <div className="client-card">
            <img src={clientIcon10} alt="Client 10" className="client-icon" />
        </div>
        <div className="client-card">
            <img src={clientIcon11} alt="Client 11" className="client-icon" />
        </div>
        <div className="client-card">
            <img src={clientIcon12} alt="Client 12" className="client-icon" />
        </div>
        <div className="client-card">
            <img src={clientIcon13} alt="Client 13" className="client-icon" />
        </div>
        <div className="client-card">
            <img src={clientIcon14} alt="Client 14" className="client-icon" />
        </div>
        <div className="client-card">
            <img src={clientIcon16} alt="Client 16" className="client-icon" />
        </div>
        
        <div className="client-card">
            <img src={m} alt="Client m" className="client-icon" />
        </div>
        <div className="client-card">
            <img src={m1} alt="Client m1" className="client-icon" />
        </div>
        
    </div>
</div>


            {/* Hire Us Section */}
            <div className="hire-us" style={{ backgroundImage: `url(${hireUsBackground})`, backgroundSize: 'cover', backgroundPosition: 'center', padding: '50px 20px', textAlign: 'center' }} data-aos="fade-in">
                <h3>Hire Us Now</h3>
                <p>We are always Ready</p>
                <a href="/contact" className="get-started-btn">Get Started</a>
            </div>

            {/* Footer Section */}
            <footer className="footer" data-aos="fade-up">
                <div className="footer-logo">
                    <img src={logo} alt="Dreamscape Studios" className="footer-logo-img" />
                    <h3>Dreamscape Studios</h3>
                    <div className="social-icons">
    <a href="https://www.instagram.com/_dreamscape.studio?igsh=Mjg2MTdvOWxkZXVl" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-instagram"></i>
    </a>
    
    <a href="https://www.linkedin.com/in/dreamscape-studios-693970313/" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-linkedin"></i>
    </a>
    <a href="mailto:dreamscapesstudiosrajasthan@gmail.com">
        <i className="fas fa-envelope"></i>
    </a>
</div>
                </div>
                

                <div className="footer-content">
                    {/* Services
                    <div className="footer-section">
                        <h4>Services</h4>
                        <p>
                            Production Management | Line Production | Location Scouting | Location Management | Casting | Equipment Rental | More
                        </p>
                    </div> */}

                    {/* Contact Information */}
                    <div className="footer-section">
                        <h4>Contact Us</h4>
                        <p><FaMapMarkerAlt /> Jaipur, Rajasthan, India</p>
                        <p>
                            <FaPhone /> 
                            <a href="tel:+917800035658" className="contact-link">+91 7800035658</a>
                        </p>
                        <p>
                            <FaEnvelope /> 
                            <a href="mailto:dreamscapesstudiosrajasthan@gmail.com" className="contact-link">dreamscapesstudiosrajasthan@gmail.com</a>
                        </p>
                    </div>
                </div>

                {/* Copyright Section */}
                <div className="footer-bottom">
                    <p>© 2024 Dreamscape Studios.</p>
                    {/* <a href="http://tirkey.in" target="_blank" rel="noopener noreferrer">tirkey.in</a> */}
                </div>
            </footer>
        </div>
    );
};

export default Home;
