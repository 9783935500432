import React, { useState } from 'react';
import { Link, Element } from 'react-scroll';
import AOS from 'aos';
import '../styles/Project.css';

const Project = () => {
    const videos = [
        { id: 1, thumbnail: 'https://img.youtube.com/vi/yADhUV2GR-0/0.jpg', link: 'https://www.youtube.com/watch?v=yADhUV2GR-0', title: '7 Wonders Music' },
        { id: 2, thumbnail: 'https://img.youtube.com/vi/xcj_8Y4Uh1k/0.jpg', link: 'https://www.youtube.com/watch?v=xcj_8Y4Uh1k', title: 'Game Theory' },
        { id: 4, thumbnail: 'https://img.youtube.com/vi/tZJTlEJXlYo/0.jpg', link: 'https://www.youtube.com/watch?v=tZJTlEJXlYo', title: 'Neelkanth IVF' },
        { id: 5, thumbnail: 'https://img.youtube.com/vi/vB7OiVfmEDM/0.jpg', link: 'https://www.youtube.com/watch?v=vB7OiVfmEDM', title: 'Netflix India' },
        { id: 6, thumbnail: 'https://img.youtube.com/vi/9QFe0eELKws/0.jpg', link: 'https://www.youtube.com/watch?v=9QFe0eELKws', title: 'Tata Motors Cars' },
        { id: 7, thumbnail: 'https://img.youtube.com/vi/rJj-HQ3mJvE/0.jpg', link: 'https://www.youtube.com/watch?v=rJj-HQ3mJvE', title: 'Parachute Advansed' },
        { id: 8, thumbnail: 'https://img.youtube.com/vi/al51WZcN2_U/0.jpg', link: 'https://www.youtube.com/watch?v=al51WZcN2_U', title: 'Air India Official' },
        { id: 9, thumbnail: 'https://img.youtube.com/vi/pUjPP6tdYc0/0.jpg', link: 'https://www.youtube.com/watch?v=pUjPP6tdYc0', title: 'Candi L.' },
        { id: 10, thumbnail: 'https://img.youtube.com/vi/b87uNYutCLs/0.jpg', link: 'https://www.youtube.com/watch?v=b87uNYutCLs', title: 'Netflix India' },
        { id: 11, thumbnail: 'https://img.youtube.com/vi/nZP9OL698as/0.jpg', link: 'https://www.youtube.com/watch?v=nZP9OL698as', title: 'Rajasthan Tourism' },
        { id: 12, thumbnail: 'https://img.youtube.com/vi/qbHW5huTBPE/0.jpg', link: 'https://www.youtube.com/watch?v=qbHW5huTBPE', title: 'Desi Dutch Originals' }
    ];

    const stills = [
        // { id: 1, src: require('../assets/stills/1.png'), title: '' },
        // { id: 2, src: require('../assets/stills/2.png'), title: '' },
        // { id: 3, src: require('../assets/stills/3.png'), title: '' },
        // { id: 4, src: require('../assets/stills/4.png'), title: '' },
        // { id: 5, src: require('../assets/stills/5.png'), title: '' },
        // { id: 6, src: require('../assets/stills/6.png'), title: '' },
        // { id: 7, src: require('../assets/stills/9.png'), title: '' }
    ];

    const posters = [
        { id: 7, src: require('../assets/stills/7.jpg'), title: '' },
        { id: 8, src: require('../assets/stills/8.png'), title: '' }
    ];

    const btsImages = [
        { id: 1, src: require('../assets/bts/1.jpg') },
        { id: 2, src: require('../assets/bts/2.jpg') },
        { id: 3, src: require('../assets/bts/3.jpg') },
        { id: 4, src: require('../assets/bts/4.jpg') },
        { id: 5, src: require('../assets/bts/5.jpg') },
        { id: 6, src: require('../assets/bts/6.jpg') },
        { id: 7, src: require('../assets/bts/7.jpg') },
        { id: 8, src: require('../assets/bts/8.jpg') },
        { id: 9, src: require('../assets/bts/9.jpg') },
        { id: 10, src: require('../assets/bts/10.jpg') },
        { id: 11, src: require('../assets/bts/11.jpg') },
        { id: 12, src: require('../assets/bts/12.jpg') },
        { id: 13, src: require('../assets/bts/13.jpg') },
        { id: 14, src: require('../assets/bts/14.jpg') },
        { id: 15, src: require('../assets/bts/15.jpg') },
        { id: 16, src: require('../assets/bts/16.jpg') },
        { id: 17, src: require('../assets/bts/17.jpg') },
        { id: 18, src: require('../assets/bts/18.jpg') },
        { id: 19, src: require('../assets/bts/19.jpg') },
        { id: 20, src: require('../assets/bts/20.jpg') },
        { id: 21, src: require('../assets/bts/21.jpg') },
        { id: 22, src: require('../assets/bts/22.jpg') },
        { id: 23, src: require('../assets/bts/23.jpg') },
        { id: 24, src: require('../assets/bts/24.jpg') },
        { id: 25, src: require('../assets/bts/25.jpg') },
        { id: 26, src: require('../assets/bts/26.jpg') },
        { id: 27, src: require('../assets/bts/27.jpg') },
        { id: 28, src: require('../assets/bts/28.jpg') },
        { id: 29, src: require('../assets/bts/29.jpg') },
        // { id: 30, src: require('../assets/bts/30.jpg') },
        { id: 31, src: require('../assets/bts/31.jpg') },
        { id: 32, src: require('../assets/bts/32.jpg') },
        { id: 33, src: require('../assets/bts/33.jpg') },
        { id: 34, src: require('../assets/bts/34.jpg') },
        { id: 35, src: require('../assets/bts/35.jpg') },
        { id: 36, src: require('../assets/bts/36.jpg') },
        { id: 37, src: require('../assets/bts/37.jpg') },
        { id: 38, src: require('../assets/bts/38.jpg') },
        { id: 39, src: require('../assets/bts/39.jpg') },
    ];

    const [selectedStill, setSelectedStill] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedPosterIndex, setSelectedPosterIndex] = useState(null);
    const [selectedBtsIndex, setSelectedBtsIndex] = useState(null);

    const openStillPopup = (index) => {
        setSelectedStill(stills[index]);
        setCurrentIndex(index);
    };

    const closePopup = () => {
        setSelectedStill(null);
        setSelectedPosterIndex(null);
        setSelectedBtsIndex(null);
    };

    const goToNextStill = () => {
        const nextIndex = (currentIndex + 1) % stills.length;
        setSelectedStill(stills[nextIndex]);
        setCurrentIndex(nextIndex);
    };

    const goToPreviousStill = () => {
        const previousIndex = (currentIndex - 1 + stills.length) % stills.length;
        setSelectedStill(stills[previousIndex]);
        setCurrentIndex(previousIndex);
    };

    const openPosterPopup = (index) => {
        setSelectedPosterIndex(index);
    };

    const goToNextPoster = () => {
        setSelectedPosterIndex((prevIndex) => (prevIndex + 1) % posters.length);
    };

    const goToPreviousPoster = () => {
        setSelectedPosterIndex((prevIndex) => (prevIndex - 1 + posters.length) % posters.length);
    };

    const openBtsPopup = (index) => {
        setSelectedBtsIndex(index);
    };

    const goToNextBtsImage = () => {
        setSelectedBtsIndex((prevIndex) => (prevIndex + 1) % btsImages.length);
    };

    const goToPreviousBtsImage = () => {
        setSelectedBtsIndex((prevIndex) => (prevIndex - 1 + btsImages.length) % btsImages.length);
    };

    return (
        
        <div className="projects">
           
            <h1>Projects</h1>
            <p className="projects-description">
                Discover our collection of innovative and creative works that span across various mediums.
            </p>

            {/* Video Thumbnails */}
            <div className="video-thumbnails">
                {videos.map((video) => (
                    <div key={video.id} className="video-thumbnail">
                        <a href={video.link} target="_blank" rel="noopener noreferrer">
                            <img src={video.thumbnail} alt={video.title} />
                            <div className="video-title">{video.title}</div>
                        </a>
                    </div>
                ))}
            </div>

            {/* Stills Section */}
            <div className="project-container">
                {/* <h2 className="stills-header">Stills</h2> */}
                <div className="stills">
                    {stills.map((still, index) => (
                        <div key={still.id} className="stills-image" onClick={() => openStillPopup(index)}>
                            <img src={still.src} alt={still.title} />
                            <div className="image-title">{still.title}</div>
                        </div>
                    ))}
                </div>
                {selectedStill && (
                    <div className="popup-overlay" onClick={closePopup}>
                        <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                            <img src={selectedStill.src} alt={`Still ${selectedStill.id}`} className="popup-image" />
                            <div className="popup-controls">
                                <button className="popup-button" onClick={goToPreviousStill}>&lt; </button>
                                <button className="popup-button" onClick={goToNextStill}> &gt;</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {/* Posters Section */}
            <div className="project-container">
                <h2 className="stills-header">Posters</h2>
                <div className="stills">
                    {posters.map((poster, index) => (
                        <div key={poster.id} className="stills-image" onClick={() => openPosterPopup(index)}>
                            <img src={poster.src} alt={poster.title} />
                        </div>
                    ))}
                </div>
                {selectedPosterIndex !== null && (
                    <div className="popup-overlay" onClick={closePopup}>
                        <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                            <img
                                src={posters[selectedPosterIndex].src}
                                alt={`Poster ${posters[selectedPosterIndex].id}`}
                                className="popup-image"
                            />
                            <div className="popup-controls">
                                <button className="popup-button" onClick={goToPreviousPoster}>&lt; </button>
                                <button className="popup-button" onClick={goToNextPoster}> &gt;</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {/* BTS Section */}
            <div className="project-container">
                <h2 className="stills-header">Behind the Scenes</h2>
                <div className="stills">
                    {btsImages.map((bts, index) => (
                        <div key={bts.id} className="stills-image" onClick={() => openBtsPopup(index)}>
                            <img src={bts.src} alt={`BTS ${bts.id}`} />
                        </div>
                    ))}
                </div>
                {selectedBtsIndex !== null && (
                    <div className="popup-overlay" onClick={closePopup}>
                        <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                            <img
                                src={btsImages[selectedBtsIndex].src}
                                alt={`BTS ${btsImages[selectedBtsIndex].id}`}
                                className="popup-image"
                            />
                            <div className="popup-controls">
                                <button className="popup-button" onClick={goToPreviousBtsImage}>&lt; </button>
                                <button className="popup-button" onClick={goToNextBtsImage}> &gt;</button>
                            </div>
                        </div>
                    </div>
                )}

                
            </div>
            

             

            
        </div>
        
    );
};

export default Project;
