import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import aboutVideo from '../assets/about.mp4'; // Background video for About Us section
import whatWeDoImage from '../assets/do.jpg'; 
import ourWorksImage from '../assets/works.jpg'; 
import hireUsBackground from '../assets/back.jpg'; 
import teamMemberOne from '../assets/one.png';
import teamMemberTwo from '../assets/two.png';
import '../styles/About.css'; 
import '@fortawesome/fontawesome-free/css/all.min.css';


const About = () => {
  // Initialize AOS animations
  React.useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="about-page">
      {/* About Us Section */}
      <div className="about-us-section" data-aos="fade-in">
        <video autoPlay loop muted className="about-background-video">
          <source src={aboutVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="about-content">
          <h1>About Us</h1>
          <h3>We are best at what we do</h3>
          <p>
            We are a passionate video production company offering full-service production, gear rental, and travel coordination. We bring your creative vision to life from concept to completion.
          </p>
          <a href="/contact" className="contact-us-btn">Contact Us</a>
        </div>
      </div>




{/* Meet The Team Section */}
<div className="meet-the-team" data-aos="fade-up">
    <h2>Meet The Team</h2>
    <div className="team-members">
{/* Team Member 2 */}
<div className="team-member">
            <img src={teamMemberTwo} alt="Team Member" className="team-member-image" />
            <h4 className="team-member-name">HARSHIT GOEL</h4>
            <p className="team-member-designation">FOUNDER & PRODUCER</p>
            <div className="social-icons">
    <a href="https://www.instagram.com/harshitgoel__/" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-instagram"></i>
    </a>
    <a href="https://www.linkedin.com/in/harshit-goel-89097a224/" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-linkedin"></i>
    </a>
    <a href="mailto:goelharshit0@gmail.com">
        <i className="fas fa-envelope"></i>
    </a>
</div>
            <p className="team-member-description">
            At Dreamscape Studios, our vision is to redefine visual storytelling through innovative production.
                Specializing in line production for films, ads, music videos, and creative shoots, we transform imaginative concepts 
                into captivating realities. Our mission is to push creative boundaries and deliver exceptional, memorable experiences.
            </p>
        </div>


        {/* Team Member 1 */}
        <div className="team-member">
            <img src={teamMemberOne} alt="Team Member" className="team-member-image" />
            <h4 className="team-member-name">LUCKY JINGAR</h4>
            <p className="team-member-designation">LINE PRODUCER</p>
            <div className="social-icons">
    <a href="https://www.instagram.com/lucky__2908/
" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-instagram"></i>
    </a>
    <a href="https://www.linkedin.com/in/luckyjingar/" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-linkedin"></i>
    </a>
    <a href="mailto:luckyjingar2908@gmail.com">
        <i className="fas fa-envelope"></i>
    </a>
</div>
            <p className="team-member-description">
            Driven by a passion for filmmaking and storytelling, Dreamscape Studio was founded to create impactful visual experiences. The journey is fueled by a love for working with diverse talents and the thrill of bringing projects to life. Despite the challenges and sleepless nights, the ultimate joy comes from creating something extraordinary. The vision is to build a studio where creativity flourishes, and each project showcases the power of collaboration and dedication.
            </p>
        </div>


    </div>
</div>




      {/* What We Do Section */}
      <div className="what-we-do-section" data-aos="fade-right">
        <div className="what-we-do-text">
          <h2>What We Do</h2>
          <p>
            Dreamscape Studios provides flexible production support. Whether it's full project management or a specific part like casting or on-set support, we handle it all. For photographers, agencies, and production companies, we offer the best talent, local expertise, and a seamless experience.
          </p>
        </div>
        <div className="what-we-do-image">
          <img src={whatWeDoImage} alt="What We Do" />
        </div>
      </div>

      {/* Services Section */}
      <div className="services-section" data-aos="fade-up">
        <h2>Our Services</h2>
        <div className="services-grid">
          <div className="service-item">Production Management</div>
          <div className="service-item">Line Production</div>
          <div className="service-item">Location Scouting</div>
          <div className="service-item">Casting</div>
          <div className="service-item">Equipment Rental</div>
          <div className="service-item">Video Production</div>
          <div className="service-item">Permits</div>
          <div className="service-item">Travel Coordination</div>
        </div>
      </div>

      {/* Our Works Section */}
      <div className="our-works-section" data-aos="fade-left">
        <div className="our-works-image">
          <img src={ourWorksImage} alt="Our Works" />
        </div>
        <div className="our-works-text">
          <h2>Our Work</h2>
          <p>
            We specialize in product, fashion, and lifestyle photography, as well as Television Commercials and Digital Films. Our work is known for compelling visual imagery, supported by the best equipment and talented professionals. We have worked with brands like Tata Harrier, Air India, and facilitated international productions like Amazing Race.
          </p>
        </div>
      </div>

      {/* Hire Us Section */}
      <div className="hire-us-section" style={{ backgroundImage: `url(${hireUsBackground})` }} data-aos="fade-up">
        <h2>Hire Us Now</h2>
        <p>We are always ready to bring your vision to life.</p>
        <a href="/contact" className="get-started-btn">Get Started</a>
      </div>

      {/* Footer */}
      <footer className="footer" data-aos="fade-up">
        
        <div className="footer-bottom">
        <div className="social-icons">
    <a href="https://www.instagram.com/_dreamscape.studio?igsh=Mjg2MTdvOWxkZXVl" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-instagram"></i>
    </a>
    
    <a href="https://www.linkedin.com/in/dreamscape-studios-693970313/" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-linkedin"></i>
    </a>
    <a href="mailto:dreamscapesstudiosrajasthan@gmail.com">
        <i className="fas fa-envelope"></i>
    </a>
</div>
          <p>&copy; Dreamscape Studios 2024</p>
          <p>
            {/* <a href="https://tirkey.in" target="_blank" rel="noopener noreferrer">tirkey.in</a> */}
          </p>
        </div>
      </footer>
    </div>
  );
};

export default About;
