import React, { useEffect } from 'react';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import emailjs from '@emailjs/browser'; 
import contactImage from '../assets/back.jpg';
import '../styles/Contact.css';

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0); 
        const AOS = require('aos');
        AOS.init({ duration: 1000 });
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        const form = e.target; // Get the form element
        emailjs.sendForm('service_vxwn36b', 'template_c4ijlpa', form, 'XcCeV_wJ7sNL9q4V-')
            .then((result) => {
                alert('Message sent successfully!');
                form.reset(); // Clear the form
            }, (error) => {
                alert('An error occurred, please try again later.'); // Error handling
                console.error('EmailJS Error:', error.text); // Log the error details
            });
    };

    return (
        <div className="contact-page">
            {/* Contact Us Section */}
            <div className="contact-header" data-aos="fade-down">
                <h1>Get in Touch</h1>
                <h4>Ready to bring your vision to life? Contact us today!</h4>
            </div>

            {/* Contact Form and Image */}
            <div className="contact-section" data-aos="fade-up">
                <div className="contact-image">
                    <img src={contactImage} alt="Contact Us" />
                </div>
                <div className="contact-form-container">
                    <div className="reach-us">
                        <h2>Reach Us At:</h2>
                        <div className="info-item">
                            <FaPhone className="contact-icon" />
                            <p>
                                
                                <a href="tel:+917800035658" className="contact-link">+91 7800035658</a>
                            </p>
                        </div>
                        <div className="info-item">
                            <FaEnvelope className="contact-icon" />
                            <p>
                                
                                <a href="mailto:dreamscapesstudiosrajasthan@gmail.com" className="contact-link">dreamscapesstudiosrajasthan@gmail.com</a>
                            </p>
                        </div>
                        <div className="info-item">
                            <FaMapMarkerAlt className="contact-icon" />
                            <p>Jaipur, Rajasthan - India</p>
                        </div>
                    </div>

                    {/* Contact Form */}
                    <div className="contact-form">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <input type="text" id="name" name="from_name" placeholder="Enter your name" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input type="email" id="email" name="from_email" placeholder="Enter your email" required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="message">Message</label>
                                <textarea id="message" name="message" rows="5" placeholder="Your message" required></textarea>
                            </div>
                            <button type="submit" className="submit-btn">Send Message</button>
                        </form>
                    </div>
                </div>
            </div>

            {/* Footer */}
      <footer className="footer" data-aos="fade-up">
        
        <div className="footer-bottom">
        <div className="social-icons">
    <a href="https://www.instagram.com/_dreamscape.studio?igsh=Mjg2MTdvOWxkZXVl" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-instagram"></i>
    </a>
    
    <a href="https://www.linkedin.com/in/dreamscape-studios-693970313/" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-linkedin"></i>
    </a>
    <a href="mailto:dreamscapesstudiosrajasthan@gmail.com">
        <i className="fas fa-envelope"></i>
    </a>
</div>
          <p>&copy; Dreamscape Studios 2024</p>
          <p>
            <a href="https://tirkey.in" target="_blank" rel="noopener noreferrer">tirkey.in</a>
          </p>
        </div>
      </footer>
        </div>
    );
};

export default Contact;
