import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link for routing
import './Navbar.css';
import logo from '../assets/logo.png';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    const handleNavLinkClick = () => {
        closeMenu(); // Close the menu when a link is clicked
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target) && isOpen) {
                closeMenu();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    return (
        <nav className={`navbar ${isOpen ? 'active' : ''}`}>
            <Link to="/" onClick={handleNavLinkClick}>
                <img src={logo} alt="Logo" className="logo" />
            </Link>
            <div className="hamburger" onClick={toggleMenu}>
                {isOpen ? 'X' : '☰'}
            </div>
            <ul className={`nav-links ${isOpen ? 'active' : ''}`} ref={menuRef}>
                <li><Link to="/" onClick={handleNavLinkClick}>Home</Link></li>
                <li><Link to="/about" onClick={handleNavLinkClick}>About</Link></li>
                <li><Link to="/projects" onClick={handleNavLinkClick}>Projects</Link></li>
                <li><Link to="/contact" onClick={handleNavLinkClick}>Contact</Link></li>
            </ul>
        </nav>
    );
};

export default Navbar;
